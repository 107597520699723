input[type="text"].form-control {
  width: 180px !important;
  border-radius: 1.5em;
}

.padding-bulk-search {
  padding-top: 18px;
}

.min-height-bulk-search {
  padding-top: 5px;
  min-height: 690px !important;
}
