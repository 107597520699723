/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  width: 80px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}
input[type="text"] {
  -moz-appearance: textfield;
  width: 110px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}
.div-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-align-right {
  display: flex !important;
  align-items: right !important;
  justify-content: right !important;
}

.td-compact-table-cell-padding {
  padding: 8px 16px 8px 8px !important;
  min-width: 125px !important;
  max-width: 125px !important;
  vertical-align: middle;
}
.td-compact-table-cell-icon {
  padding: 8px 16px 8px 8px !important;
  min-width: 185px !important;
  max-width: 185px !important;
  align-items: right !important;
}

.isTableLoading {
  background-color: er-black !important;
  color: er-white !important;
}

.header {
  width: 100%;
  background-color: #c9c7c4;
  color: black;
  text-align: center;
  padding-top: 5px;
}

.footer {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  margin-top: -40px;
  clear: both;
  background-color: #191919;
  color: white;
  text-align: center;
}

.component-main {
  overflow: auto;
  padding-bottom: 40px;
}

.thead {
  background-color: #d1d1d1 !important;
  color: black;
  border-radius: 0 !important;
}

.bg-color-tabs {
  background-color: #f0f0f0;
}
.padding-tabs {
  padding-top: 5px;
}
.bg-color {
  background-color: #f7f7f7;
}
.icon-size {
  width: 60px;
  height: 60px;
}

.td-table-cell-word-wrap {
  word-wrap: break-word !important;
}

.padding-message-table {
  padding-bottom: 100px;
}
