/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number-np"] {
  -moz-appearance: textfield;
  width: 240px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}

input[type="text-np"] {
  -moz-appearance: textfield;
  width: 240px !important;
  height: 36px !important;
  margin-bottom: 0px !important;
}

.div-label {
  margin-top: 15px;
  width: 100%;
}

.preview-icon {
  color: #8b8888;
}

.preview-icon:hover {
  color: #000000;
}

.button {
  width: 183px;
}

.button-padding {
  padding-left: 30px;
  padding-top: 10px;
}

.button-padding-carrier {
  padding-left: 80px;
  padding-bottom: 5px;
}
