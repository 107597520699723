.sub-label {
  color: #123b6e;
}

.table-label {
  color: #af5329;
}

.modal-align-left {
  float: left;
}
