.proposed-plan-root-div{
    width: 80%;
    padding: 10px
}

.proposed-div-padding{
    padding: 20px;
}

.proposed-section-div-position{
    display: flex;
    padding: 5px;
}

.proposed-section-div-padding-left{
    padding-left: 4%;
}

.proposed-table-div{
    width: 50%;
    padding: 15px;
    background-color: #f7f7f7
}

.proposed-div-float-left{
    float: left;
}

.proposed-div-float-right{
    float: right;
}
